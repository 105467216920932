import { getPatientSleepStatistics } from 'api/alerts.js';
import { FallPreventionAlertTypes, SleepQualityStatTypes } from 'constants/ai.js';
import translate from 'i18n-translations/translate.jsx';
import DoorIcon from 'icons/Dashboard/DoorIcon.jsx';
import { VoiceIcon } from 'icons/Monitoring/index.js';
import { timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import CustomStatsWidget from 'components/DashboardCharts/PatientCentric/AI/Stats/CustomStatsWidget.jsx';
import { DashboardWidget } from 'constants/dashboard.js';
import { AiAnalyticsTypeId } from 'constants/enums.js';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';

const SleepStats = ({ dateRange, selectedTimezone }) => {
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [sleepStatistics, setSleepStatistics] = useState(null);
	const [error, setError] = useState('');
	const { iconColor, backgroundColor } = DashboardWidget.PURPLE;

	const SleepStats = [
		{
			id: AiAnalyticsTypeId.DECIBEL_LEVEL,
			icon: <VoiceIcon color={iconColor} width={30} height={30} />,
			label: translate('averageDecibelLevel'),
			total: sleepStatistics?.[SleepQualityStatTypes.AVERAGE_DECIBEL_LEVEL] ?? 0,
		},
		{
			id: AiAnalyticsTypeId.PERSON_ENTERING_THE_ROOM,
			icon: <DoorIcon color={iconColor} />,
			label: translate('personEnteringTheRoom'),
			total: sleepStatistics?.[SleepQualityStatTypes.PERSON_ENTERING_THE_ROOM] ?? 0,
		},
		{
			id: AiAnalyticsTypeId.PATIENT_GETTING_OUT_OF_BED,
			icon: <OutOfBed color={iconColor} width={30} height={30} />,
			label: translate('bedExits'),
			total: sleepStatistics?.[SleepQualityStatTypes.BED_EXITS] ?? 0,
		},
	];

	const fetchSleepStatistics = useCallback(
		debounce(async params => {
			const response = await getPatientSleepStatistics(params);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setSleepStatistics(response);
			setIsLoading(false);
			setError('');
		}, 500),
		[]
	);

	useEffect(() => {
		const { patient } = location.state;

		const params = {
			userId: patient?.healthcareUserId,
			alertTypes: FallPreventionAlertTypes,
			start: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
		};

		fetchSleepStatistics(params);
	}, [location, dateRange, fetchSleepStatistics, selectedTimezone.zone]);

	return (
		<CustomStatsWidget
			isLoading={isLoading}
			error={error}
			title={translate('sleepStats')}
			items={SleepStats}
			color={backgroundColor}
		/>
	);
};
export default SleepStats;

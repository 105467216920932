import { useContext, useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import SleepQualityIcon from 'icons/Monitoring/SleepQualityIcon.jsx';
import { SleepQualityStats, SleepWidget, ValidSleepQualityTypes } from 'constants/ai.js';
import AiStatWidget from 'components/Monitoring/AI/AiStatWidget.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { AiAnalyticsTypeId } from 'constants/enums.js';

const SleepQuality = ({ deviceId, patientId }) => {
	const socket = useContext(SocketContext);
	const [expandedBox, setExpandedBox] = useState(true);
	const [sleepStats, setSleepStats] = useState(SleepQualityStats);
	const { backgroundColor, color } = SleepWidget.PURPLE;

	useEffect(() => {
		const onAlertAnalyticsAdded = data => {
			if (!ValidSleepQualityTypes.includes(data.analyticsType) || data.deviceId !== deviceId || data.patientId !== patientId) {
				return;
			}
			const isPersonEnteringRoom = data.analyticsType === AiAnalyticsTypeId.PERSON_ENTERING_THE_ROOM;
			const newValue =
				isPersonEnteringRoom && data?.value
					? (sleepStats.find(stat => stat.id === AiAnalyticsTypeId.PERSON_ENTERING_THE_ROOM)?.value || 0) + 1
					: data?.value;

			setSleepStats(prevStats => prevStats.map(stat => (stat.id === data.analyticsType ? { ...stat, value: newValue } : stat)));
		};

		socket.on(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		return () => {
			socket.off(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		};
	}, [socket, sleepStats]);

	return (
		<div className='monitoring-timeline-box sleep-quality'>
			<div className='timeline-box-header'>
				<p className='timeline-box-title flex-1'>
					<SleepQualityIcon />
					{translate('sleepQuality')}
				</p>
				<div className='timeline-box-actions'>
					<Button
						type='button'
						onClick={() => setExpandedBox(prevState => !prevState)}
						icon={expandedBox ? 'expand_less' : 'expand_more'}
					/>
				</div>
			</div>
			{expandedBox && (
				<div className='timeline-box-content'>
					<div className='bottom-20'>
						{sleepStats.map(item => (
							<AiStatWidget
								key={item.id}
								label={item.label}
								value={item.value}
								icon={item.icon}
								backgroundColor={backgroundColor}
								color={color}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default SleepQuality;

import classNames from 'classnames';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';

const AiConfigCheckbox = ({ aiConfig, arrayName, onCheckboxChange, onSetAsDefault, shouldSetAsDefault }) => (
	<div className='sector tick-box-active-input flex bottom-5 margin-right-m flex-align-center'>
		<label className='flex remember-me cursor-pointer no-margin'>
			<input
				type='checkbox'
				checked={aiConfig.isChecked}
				name={aiConfig.label}
				onChange={event => onCheckboxChange({ event, aiConfigValue: aiConfig.value, arrayName })}
			/>
			<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
				<div className='onb-custom-checkbox blue-check-box' />
			</div>
			<span className='left-s margin-right-m'>{aiConfig.label}</span>
		</label>
		{aiConfig.isChecked && shouldSetAsDefault && (
			<Button
				className={classNames('set-ai-default-btn', { 'default-btn': aiConfig.isDefault })}
				onClick={() => onSetAsDefault(aiConfig.value, arrayName)}
				icon={aiConfig.isDefault ?? 'done'}
				text={translate(aiConfig.isDefault ? 'default' : 'setAsDefault')}
			/>
		)}
	</div>
);
export default AiConfigCheckbox;
